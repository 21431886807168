var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ref, watch, reactive, h } from 'vue';
import DuplicationModal from './components/DuplicationModal.vue';
export const useDuplicationModal = (options) => {
    const state = reactive({
        isLoading: false,
        isOpen: false,
        name: '',
    });
    const renderFunction = ref(h('div'));
    watch(state, () => {
        renderFunction.value = h(DuplicationModal, {
            isOpen: state.isOpen,
            popupTitle: options.title,
            cloneName: state.name,
            inputPlaceholder: options.placeholder,
            loadingState: state.isLoading,
            saveButtonText: options.saveButtonText,
            onClose: () => (state.isOpen = false),
            onSave: () => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b;
                state.isLoading = true;
                try {
                    // Save handler MAY return a response or throw an error
                    const response = yield options.save(state.name);
                    state.isLoading = false;
                    if (response === null || response === void 0 ? void 0 : response.success) {
                        (_a = options.onSuccess) === null || _a === void 0 ? void 0 : _a.call(options);
                        state.isOpen = false;
                    }
                    else {
                        let message;
                        if (!(response === null || response === void 0 ? void 0 : response.success)) {
                            message = response === null || response === void 0 ? void 0 : response.value.resourceKey;
                        }
                        (_b = options.onError) === null || _b === void 0 ? void 0 : _b.call(options, message);
                    }
                }
                catch (e) {
                    if (options.onError)
                        options.onError();
                }
            }),
            onChangeName: (newName) => {
                state.name = newName;
            },
        });
    });
    return {
        render: renderFunction,
        setName: (newName) => (state.name = newName),
        open: () => (state.isOpen = true),
    };
};
