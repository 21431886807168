import "systemjs-webpack-interop/auto-public-path";
import { lifecycle } from './app.js'
import { routes } from './router.js'
import { acl } from '@dnx/core';
import Dashboard from './pages/Overview.vue'

// singleSpa & mountParcel also in props
function createManifest() {
  const { name, version, description, dnx } = require('../package.json')
  const defaults = {
    color: '#ff0000',
    icon: 'gear',
  }
  
  let manifest = {
    name, 
    version, 
    description,
    ...defaults,
    ...dnx
  }
  return manifest
}

const manifest = createManifest()
export default manifest

const bridge = {
  name: manifest.name,
  bootstrap: async (props) => { const {
      name,
      singleSpa
    } = props
  },
  mount: async (props) => { 
    const { interop } = props
    interop?.register( bridge )
  },
  unmount: async (props) => { 
    const { interop } = props
    interop?.unregister( bridge )  
  }
}

const core = {
  mount : async (props) => {
    // should be registered from package.json automatically
    acl.registerPermission('templatevariables', '685b213d-9320-4b20-894d-871a429c9102')
    acl.registerPermission('templateblocklibrary', 'c6ee1b30-625f-446c-b77f-552d2c0ff3ee')
    
    const { name, mountParcel, customProps } = props;
    for(const [key,value] in customProps)
      manifest[key] = value;
    manifest.csscolor = `var(--module-${manifest.name}, ${manifest.color})`;
    await mountParcel( bridge, { domElement: document.body, interop: Window.interop  })

    Window._shellReady.then(() => {
      document.dispatchEvent( new CustomEvent('dnx:app-loaded', {
        detail:{
          appmodule: name,
          config: {
            component: Dashboard,
            routes
          }
        }} ) )
    })
  },
}

export const bootstrap = [
  lifecycle.bootstrap,
];

// Mount is called once when active is truthy, after that application should handle route/hashchange/popstate
export const mount = [
  core.mount,
  //DEV: returning mount directly fails mounting. Needs to be fully promisified
  lifecycle.mount
];
export const unmount = [
  lifecycle.unmount,
];
export const update = [
  core.update,
  lifecycle.update,
];
// optional [unloadapplication is called]
export const unload = [
  lifecycle.unload,
]; 

// Overlay in spa-inspector
export const devtools = {
  overlays: {
    selectors: [
      `.${manifest.name}`
    ],
    options: {
      width: '33vw',
      height: 'auto',
      zIndex: 999,
      position: 'absolute',
      top: 0,
      left: 0,
      color: '#000',
      background: '#ffffff80',
      textBlocks: [ manifest.title ],
    },
  },
};