import {createWebHistory, createRouter} from 'vue-router';

/*  
  The preferred use is a sub-router.
*/

export const routes = [
  {
    path: '/',
    name: 'emdm-overview',
    component: () => import('./pages/Overview.vue'),
    meta: {sidebar: true},
  },
  {
    path: '/edit/:type/edit/:id',
    name: 'emdm-editor',
    component: () => import('./pages/Detail.vue'),
    meta: {sidebar: true, confirmLeave: true},
  },
  {
    path: '/variables',
    name: 'emdm-variable-manager',
    component: () => import('./pages/VariableManager.vue'),
    meta: {sidebar: true},
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
