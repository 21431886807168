var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
class Scenarios {
    constructor() {
        this.paths = Object.freeze({
            getScenarios: (divisionid) => `api/Scenarios/${encodeURIComponent(divisionid)}`,
            activate: (divisionid, scenarioid) => `api/Scenarios/${encodeURIComponent(divisionid)}/${encodeURIComponent(scenarioid)}/activate`,
        });
    }
    /**
     * Scenarios.GetScenarios "GET api/Scenarios/{divisionId}"
     * @param {Guid} divisionid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ScenarioModel[]>>}
     */
    getScenarios(divisionid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/Scenarios/${divisionid === undefined || divisionid === null ? "" : encodeURIComponent(divisionid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Scenarios.Activate "PATCH api/Scenarios/{divisionId}/{scenarioId}/activate"
     * @param {Guid} divisionid
     * @param {Guid} scenarioid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    activate(divisionid, scenarioid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/Scenarios/${divisionid === undefined || divisionid === null ? "" : encodeURIComponent(divisionid)}/${scenarioid === undefined || scenarioid === null ? "" : encodeURIComponent(scenarioid)}/activate`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PATCH");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const scenarios = new Scenarios();
export { scenarios as default, Scenarios };
