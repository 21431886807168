var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
/**
 * Controller for verifying correct loading of the DNX Share module
 */
class Share {
    constructor() {
        this.paths = Object.freeze({
            list: () => `api/share`,
            getById: (id) => `api/share/${encodeURIComponent(id)}`,
            getByDataId: (datatype, dataid) => `api/share/${encodeURIComponent(datatype)}/${encodeURIComponent(dataid)}`,
            getByDataType: (datatype) => `api/share/${encodeURIComponent(datatype)}`,
            delete: (id) => `api/share/${encodeURIComponent(id)}`,
            deleteItem: (dataid, datatype) => `api/share/${encodeURIComponent(datatype)}/${encodeURIComponent(dataid)}`,
            create: (dataid, datatype) => `api/share/${encodeURIComponent(datatype)}/${encodeURIComponent(dataid)}`,
            createShare: (id, dataid, datatype) => `api/share/${encodeURIComponent(datatype)}/${encodeURIComponent(dataid)}/${encodeURIComponent(id)}`,
        });
    }
    /**
     * Share.List "GET api/share"
     *
     * List all share resources
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<SharedItemModel[]>>} List of share resources
     */
    list(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/share`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getUnauthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Share.GetById "GET api/share/{id}"
     *
     * Get a single share resource by id
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<SharedItemModel[]>>} Share resource associated with id
     */
    getById(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/share/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getUnauthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Share.GetByDataId "GET api/share/{datatype}/{dataid}"
     *
     * Get a single share resource by id
     * @param {string} datatype
     * @param {Guid} dataid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<SharedItemModel[]>>} Share resource associated with id
     */
    getByDataId(datatype, dataid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/share/${datatype === undefined || datatype === null ? "" : encodeURIComponent(datatype)}/${dataid === undefined || dataid === null ? "" : encodeURIComponent(dataid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getUnauthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Share.GetByDataType "GET api/share/{datatype}"
     *
     * Get a single share resource by id
     * @param {string} datatype
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<SharedItemModel[]>>} Share resource associated with id
     */
    getByDataType(datatype, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/share/${datatype === undefined || datatype === null ? "" : encodeURIComponent(datatype)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getUnauthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Share.Delete "DELETE api/share/{id}"
     *
     * Delete a single share
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>} Share resource associated with id
     */
    delete(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/share/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getUnauthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Share.DeleteItem "DELETE api/share/{datatype}/{dataid}"
     *
     * Delete a single share
     * @param {Guid} dataid
     * @param {string} datatype
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>} Share resource associated with id
     */
    deleteItem(dataid, datatype, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/share/${datatype === undefined || datatype === null ? "" : encodeURIComponent(datatype)}/${dataid === undefined || dataid === null ? "" : encodeURIComponent(dataid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getUnauthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Share.Create "POST api/share/{datatype}/{dataid}"
     *
     * Create a new shared item.
     * @param {Guid} dataid
     * @param {string} datatype
     * @param {SharedItemModel[]} shares
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>} Share resource associated with id
     */
    create(dataid, datatype, shares, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/share/${datatype === undefined || datatype === null ? "" : encodeURIComponent(datatype)}/${dataid === undefined || dataid === null ? "" : encodeURIComponent(dataid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getUnauthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, shares);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Share.CreateShare "POST api/share/{datatype}/{dataid}/{id}"
     *
     * Create a new share.
     * @param {Guid} id
     * @param {Guid} dataid
     * @param {string} datatype
     * @param {SharedItemModel} shares
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>} Share resource associated with id
     */
    createShare(id, dataid, datatype, shares, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/share/${datatype === undefined || datatype === null ? "" : encodeURIComponent(datatype)}/${dataid === undefined || dataid === null ? "" : encodeURIComponent(dataid)}/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getUnauthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, shares);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const share = new Share();
export { share as default, Share };
