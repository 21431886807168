import {defineStore} from 'pinia';
import api from '../../app/api-generated/template';
import {T, Uuid, isEmpty} from '@dnx/core';
import scenarioApi from '../../app/api-generated/scenarios'
import areasApi from '../../app/api-generated/areas'
import shareApi from '../../app/api-generated/share'

// Is the object empty (null,undefined,empty array, empty guid, empty string, empty object)

const newestFirst = (lhs,rhs) => Date.parse(rhs.created) - Date.parse(lhs.created)

export const useEmdmStore = defineStore('emDmStore', {
  state: () => ({
    templates: [],
    baseTemplates: [],
    selectedModule: 'em',
    emptyTemplate: {name: 'Empty', description: 'Empty template', id: undefined},

    types: {
      em: {
        id: 'em',
        type: undefined,
        icon: 'at-sign',
        title: T('EM_EDITOR_TITLE'),
        name: T('TEMPLATE_EDITOR_EM'),
        description: T('TEMPLATE_EDITOR_EM_TEMPLATES_DESC')
      },
      dm: {
        id: 'dm',
        type: undefined,
        icon: 'mail',
        title: T('DM_EDITOR_TITLE'),
        name: T('TEMPLATE_EDITOR_DM'),
        description: T('TEMPLATE_EDITOR_DM_TEMPLATES_DESC')
      },
    },
    
    // can we remove this in favor of types?
    em: {
      icon: 'at-sign',
      title: T('EM_EDITOR_TITLE'), // Temporary, resolve title, default to EM
      name: T('TEMPLATE_EDITOR_EM'),
      description: T('TEMPLATE_EDITOR_EM_TEMPLATES_DESC'),
    },
    dm: {
      icon: 'mail',
      title: T('DM_EDITOR_TITLE'),
      name: T('TEMPLATE_EDITOR_DM'),
      description: T('TEMPLATE_EDITOR_DM_TEMPLATES_DESC'),
    },
    scenarios: [],
    divisions: [],
  }),
  getters: {
    moduleProperties(state) {
      return state[this.selectedModule];
    },
  },
  actions: {
    async fetchTemplates(type) {
      const myDivision = Window.runtime.store.getters['users/getCurrentUser']?.areaId
      const response = await api.list(type);
      this.templates = response.value.filter(x => !x.isBaseTemplate).sort(newestFirst);
      for( const tpl of this.templates) {
        tpl.canShare = tpl.sharedBy && (tpl.sharedBy === myDivision || tpl.sharedBy === Uuid.Empty().toString())
        tpl.sharedByMe = tpl.sharedBy === myDivision
        tpl.sharedWithMe = !tpl.isSharedByMe && tpl.sharedWith && tpl.sharedWith.includes( myDivision )
      }
        
      this.baseTemplates = response.value.filter(x => x.isBaseTemplate);
    },
    async deleteTemplate(templateId) {
      if (this.templates
        .filter(item => item.id === templateId)
        .some( tpl => tpl.sharedBy && tpl.sharedBy !== Uuid.Empty().toString() )
      ) {
        return {success: false, reason: T('ERROR_TEMPLATE_IN_USE')};
      }

      return await api.delete(templateId);
    },
    async fetchScenario(divId) {
      this.scenarios = (await scenarioApi.getScenarios(divId)).value;
      this.divisions = (await areasApi.getSummaries()).value;
    },
    async updateActiveScenario(divisionId, scenarioId) {
       const response = await scenarioApi.activate(divisionId, scenarioId);
    },
    async shareTemplate(template) {
      await Promise.all( this.templates
        .filter(item => item.id === template.id)
        .map( async tpl => {
          if (~~tpl.sharedWith?.length > 0) {
            tpl.sharedWith = []
            delete tpl.sharedBy
            await shareApi.deleteItem(tpl.id, 'template')
          } else {
            const divisionId = Window.runtime.store.getters['users/getCurrentUser']?.areaId
            tpl.sharedWith = [ divisionId ]
            tpl.sharedBy = divisionId
            await shareApi.create(
              tpl.id, 
              'template', 
              tpl.sharedWith.map( division => ({
                sharedWithDivisionId: division,
                includeChildren: true,
                dataType: 'template',
                dataId: tpl.id,                
              }))
            )
          }
        })
      )
      .then ( () => this.fetchTemplates(this.selectedModule))
    },
    async clone(templateId, name) {
      const response = await api.clone(templateId, name)
      if (response.success)
        await this.fetchTemplates(this.selectedModule)
      return response
    },


    getBaseTemplates( parent ) {
      if (!parent) return Object.values(this.types);

      //DEV: No parentId in template yet
      if (parent.length>4) {
        const list = this.baseTemplates.filter( ({id}) => id === parent );
        return isEmpty(list) ? [ emptyTemplate ] : list
      }

      return [
        emptyTemplate,
        ...this.baseTemplates.filter( ({type}) => type?.toLowerCase() === parent?.toLowerCase())
      ]
    }
  },
  
});
