<template>
  <XHeaderBox
    iconName="template"
    iconColor="#FFFFFF"
    iconBackground="var(--module-emdm-editor, #075087)"
    titleColor="var(--module-emdm-editor, #075087)"
    :headerTitle="T('TEMPLATE_EDITOR_TEMPLATES')"
  >
    <template #rightButtonSlot>
      <XRouterLink v-if="$can.read.templatevariables" :to="{name: 'emdm-variable-manager'}">
        <XButton icon="sliders">Variable Manager</XButton>
      </XRouterLink>

      <XButton color="success" icon="plus-circle" @click="selectModule()" :disabled="!$can.write.emdm">
        {{ T('TEMPLATE_EDITOR_MAKE_TEMPLATE') }}
      </XButton>
    </template>
    <template #bottomSlot>
      <XStack padding="md">
        <XInput
          :placeholder="T('TEMPLATE_EDITOR_SEARCH_TEMPLATE')"
          XIconName="search"
          XIconColor="grey"
          width="15rem"
          :modelValue="state.templateSearchValue"
          @update:modelValue="state.templateSearchValue = $event"
          autocomplete="off"
        />
      </XStack>
    </template>
  </XHeaderBox>
  <XWrapper>
    <div class="template-container">
      <XParagraph v-if="state.noResults">
        {{ T('OVERVIEW_SEARCH_NO_RESULTS') }}
      </XParagraph>
      <XPreviewBox
        hoverColor="var(--module-emdm-editor, #075087)"
        v-for="template in filteredTemplates"
        :key="template.id"
        :title="template.name"
        :link="{
          name: 'emdm-editor',
          params: {
            type: template.type?.toLowerCase() ?? emDmStore.selectedModule,
            id: template.id,
          },
        }"
      >
        <template #menuItems>
          <div v-if="template.canShare" class="menu-item" @click.prevent="shareItem(template)">
            <XIcon name="person-add" />
            <XParagraph componentStyle="medium">
              {{ T('TEMPLATE_EDITOR_SHARE') }}
            </XParagraph>
          </div>
          <div v-if="template.canEdit" class="menu-item" @click.prevent="copyItem(template)">
            <XIcon name="copy" />
            <XParagraph componentStyle="medium">
              {{ T('TEMPLATE_EDITOR_DUPLICATE') }}
            </XParagraph>
          </div>
          <hr
            v-if="template.canEdit || template.canShare"
            style="flex-grow: 1; border: none; height: 1px; background-color: var(--control-border, #c9d3df)"
          />
          <div
            v-if="template.canEdit"
            class="menu-item"
            @click.prevent="template.sharedWith.length == 0 ? deleteTemplate(template) : displayShareWarning()"
          >
            <XIcon name="delete" color="var(--button-danger, #D21460)" />
            <XParagraph componentStyle="medium" color="var(--button-danger, #D21460)">
              {{ T('DELETE') }}
            </XParagraph>
          </div>
        </template>
        <template #preview>
          <img src="/assets/DM-placeholder.png" alt="image" />
        </template>
        <template #top-left>
          <XIcon
            :name="emDmStore[template.type.toLowerCase()].icon"
            color="#FFFFFF"
            :hoverEffect="true"
            background="var(--module-emdm-editor, #075087)"
          />
          <XIcon
            name="2-persons"
            :hoverEffect="true"
            color="#FFFFFF"
            background="var(--module-emdm-editor, #075087)"
            v-if="template.sharedByMe"
          />
          <XIcon
            name="person"
            :hoverEffect="true"
            color="#FFFFFF"
            background="var(--module-emdm-editor, #075087)"
            v-else-if="template.sharedWithMe"
          />
        </template>
        <template #details>
          <div class="detail-box">
            <XParagraph
              componentStyle="md"
              weight="semi-bold"
              color="var(--module-emdm-editor, #075087)"
              :title="template.name"
            >
              {{ template.name }}
            </XParagraph>
            <XParagraph componentStyle="md" color="var(--text-primary, #313741)">
              {{ $format(template.created, 'date') }}
            </XParagraph>
          </div>
        </template>
      </XPreviewBox>
    </div>
  </XWrapper>
  <component :is="duplicationModal.render.value" class="popup" />
  <ShareItem
    v-if="state.isEditing"
    :cancel="() => (state.isEditing = false)"
    @ok="toggleShare()"
    :shared="!!state.shared"
    @update:shared="state.shared = $event"
  />
</template>

<script setup>
import {reactive, computed, h} from 'vue';
import {useRouter} from 'vue-router';
import {useConfirmStore} from '../stores/confirmation';
import {useEmdmStore} from '../stores/store';

import {useEditorStore} from '../stores/editor';
import ShareItem from './ShareItem.vue';
import SelectTemplate from './SelectTemplate.vue';
import {useDuplicationModal} from '../composables';
import {useDelete, useNotifications, T} from '@dnx/core';
import {onMounted, onUnmounted} from 'vue';

const emDmStore = useEmdmStore();
const confirmStore = useConfirmStore();
const router = useRouter();
const editorStore = useEditorStore();
const notifications = useNotifications();

const state = reactive({
  templateSearchValue: '',
  noResults: false,
  moduleSelected: false,
  isEditing: false,
  template: null,
  shared: false,
});

//DEV: previewbox only shows 0-2 icons!

//fetch all templates
emDmStore.fetchTemplates();

const displayShareWarning = async () => {
  await confirmStore.confirm(T('EMDM_OVERVIEW_SHARE_DELETE_TITLE'), T('EMDM_OVERVIEW_SHARE_DELETE_BODY'), {
    status: 'error',
    confirmButton: {buttonStyle: 'primary'},
  });
};

const deleteTemplate = useDelete({
  promptTitle: 'TEMPLATE_EDITOR_CONFIRM_DELETE_PERMANENTLY_TITLE',
  promptMessage: 'TEMPLATE_EDITOR_CONFIRM_DELETE_PERMANENTLY_MESSAGE',
  notificationSuccess: 'TEMPLATE_EDITOR_CONFIRM_DELETED_SUCCESS',

  delete: async id => {
    return emDmStore.deleteTemplate(id, emDmStore.selectedModule);
  },
  deleted: async () => {
    return await emDmStore.fetchTemplates(emDmStore.selectedModule);
  },
});

const duplicationModal = useDuplicationModal({
  save: async name => {
    return await emDmStore.clone(state.selected?.id, name);
  },
  onSuccess: () => notifications.successNotification(T('COPIED')),
  onError: message => notifications.errorNotification(message ? T(message) : T('ERROR_NONE')),
  title: T('TEMPLATE_EDITOR_DUPLICATE_TEMPLATE_SAVE_COPY'),
  placeholder: T('TEMPLATE_EDITOR_DUPLICATE_NAME'),
  saveButtonText: T('OK'),
});

const actions = {
  ok(template) {
    console.log(template);
    actions.close();
    router.push(
      `/emdm/edit/${template.type?.toLowerCase() ?? 'em'}/edit/00000000-0000-0000-0000-000000000000?baseTemplateId=${
        template.id ?? ''
      }`
    );
  },
  close: () => confirmStore.$reset(),
};

const selectModule = () => {
  confirmStore.updateBoxProperties({
    title: T('TEMPLATE_EDITOR_SELECT_TEMPLATE'),
    borderColor: 'var(--main-primary, #2783C6)',
    componentName: h(SelectTemplate, {
      data: {
        em: emDmStore['em'],
        dm: emDmStore['dm'],
      },
    }),
    actions,
    buttons: [confirmStore.defaultButton.cancel],
    customWidth: '20rem',
  });
};

const shareItem = template => {
  state.selected = template;
  state.shared = template.sharedWith?.length > 0;
  state.isEditing = true;
};

function copyItem(template) {
  state.selected = template;
  duplicationModal.open();
  duplicationModal.setName(`${template.name} - Copy`);
}

async function toggleShare() {
  await emDmStore.shareTemplate(state.selected);
  state.isEditing = false;
}

const filteredTemplates = computed(() => {
  state.noResults = false;

  if (!emDmStore.templates) {
    return;
  }

  const searchValue = state.templateSearchValue.toLowerCase();
  const filteredResults = emDmStore.templates.filter(template => template.name.toLowerCase().includes(searchValue));

  if (filteredResults.length === 0 && searchValue) {
    return (state.noResults = true);
  }

  return filteredResults;
});
</script>

<style lang="scss" scoped>
.template-container {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;

  .menu-item {
    display: flex;
    align-items: center;
    column-gap: 0.2rem;
  }

  .icon {
    cursor: pointer;
  }

  .detail-box {
    .paragraph.medium {
      margin: 0;
      font-weight: 600;
      width: 100%;
      max-height: 1.6rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
    }
  }
}
</style>
