<template>
  <XPopup :title="popupTitle" v-if="isOpen" :showBackground="true" setWidth="50rem" @close="$emit('close')">
    <XWrapper padding="md">
      <XInput :placeholder="inputPlaceholder" :autoSelect="true" v-model="name" />
    </XWrapper>
    <template #footer>
      <XButton componentStyle="white" @click="$emit('close')">
        {{ T('CANCEL') }}
      </XButton>
      <XButton componentStyle="primary-success" :state="loadingState ? 'loading' : null" @click="$emit('save', name)">
        <XIcon name="check-circle" />
        {{ saveButtonText }}
      </XButton>
    </template>
  </XPopup>
</template>
<script setup>
import {T} from '@dnx/core';
import {watch, ref, toRefs} from 'vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
  },
  popupTitle: {
    type: String,
  },
  cloneName: {
    type: String,
  },
  inputPlaceholder: {
    type: String,
  },
  loadingState: {
    type: Boolean,
  },
  saveButtonText: {
    type: String,
  },
});

const {cloneName} = toRefs(props);

const emits = defineEmits(['close', 'save', 'changeName']);

const name = ref(props.cloneName);

watch(cloneName, newName => {
  name.value = newName;
});

watch(name, newName => {
  emits('changeName', newName);
});
</script>

<style lang="scss" scoped>
.popup-wrapper {
  user-select: none;
  :deep .popup-body {
    padding: 1.25rem;
  }
}
</style>
